<template>
  <div>
    <Loading :center="false" v-show="loading" />
    <div v-show="!loading">
      <!-- É renderizado o html abaixo para modificar as tabelas -->
      <div id="template-prod" v-show="false" v-html="template"></div>
      <b-row>
        <b-col class="text-center">
          <Button v-print="'#printMe'" _key="print" type="edit" title="Imprimir" classIcon="fas fa-print" size="small"
            :clicked="print" />
          <Button _key="back" type="info" classIcon="fa-solid fa-arrow-left" size="small" :clicked="executeBack" />
        </b-col>
      </b-row>
      <br />
      <ScrollBar :minHeight="400" :maxHeight="600">
        <div>
          <div class="a4">
            <div id="printMe">
              <div :style="styleCss">
                <DocumentPublic :template="documentPreview('prod')" :d="parameter" />
              </div>
            </div>
          </div>
        </div>
      </ScrollBar>
    </div>
  </div>
</template>
<script>
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import DocumentPublic from "@nixweb/nixloc-ui/src/component/shared/DocumentPublic";

import print from "vue-print-nb";

import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "DocumentPublicView",
  directives: {
    print,
  },
  props: {
    documentId: String,
    rentId: String,
    periodRentId: String,
    movimentId: String,
    invoiceId: String,
    payableReceivableId: String,
    orderServiceId: String,
    back: Function,
  },
  components: {
    DocumentPublic,
    Loading,
    ScrollBar,
    Button,
  },
  data() {
    return {
      urlGetById: "/api/v1/generate-document/get-document",
      template: "",
      parameter: {},
      loading: true,
      styleCss: "",
    };
  },
  mounted() {
    this.getDocumentById();
  },
  computed: {
    ...mapGetters("generic", ["documentPreview"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["updateDocumentHtml", "removeLoading"]),
    getDocumentById() {
      this.loading = true;
      let params = {
        url: this.urlGetById,
        obj: {
          documentId: this.documentId,
          rentId: this.rentId,
          periodRentId: this.periodRentId,
          movimentId: this.movimentId,
          invoiceId: this.invoiceId,
          payableReceivableId: this.payableReceivableId,
          orderServiceId: this.orderServiceId,
        },
      };
      this.getApi(params).then((response) => {
        this.styleCss = response.content.styleCss;
        this.parameter = response.content.parameter;
        this.template = response.content.documentHtml;
        let self = this;
        setTimeout(function () {
          self.updateDocumentHtml(response.content.documentHtml);
          self.template = self.documentPreview;
          self.loading = false;
        }, 300);
      });
    },
    print() {
      this.removeLoading(["print"]);
    },
    executeBack() {
      if (this.back) this.back();
    },
  },
};
</script>
<style scoped>
.a4 {
  max-width: 1140px;
  min-height: 21cm;
  padding: 20px;
  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;
  box-shadow: 0 0 5px hsl(0deg 0% 0% / 10%);
  margin: 0 auto;
}
</style>
