<template>
  <div>
    <Loading :center="false" v-show="loading" />
    <div class="text-center">
      <ButtonFilter :options="optionsConditional" initialOption="print" v-model="selectType" />
    </div>
    <CheckboxGroup
      v-show="selectType == 'PDF' || selectType == 'signature' || selectType == 'mail' || selectType == 'link'"
      :stacked="true" field="options" :options="data" v-model="selectedPdf" />
    <RadioGroup v-show="selectType == 'print'" field="permission" :markFormDirty="false" :stacked="true" :options="data"
      v-model="selectedPrint" />
    <Alert type="info" v-if="data.length == 0 && !loading && module != 'TaxDocument'"
      v-show="selectType == 'print' || selectType == 'PDF' || selectType == 'signature' || selectType == 'mail' || selectType == 'link'">
      <span> Nenhum documento! </span>
    </Alert>
    <slot></slot>
    <div class="text-center">
      <div class="side-by-side">

        <Button v-if="selectType == 'print'" _key="btnClickedNext" type="info" title="Visualizar"
          :disabled="!selectedPrint" classIcon="fa-solid fa-print-magnifying-glass" size="medium" :clicked="execute"
          :params="'view'" />


        <Button v-if="selectType == 'PDF'" _key="btnGeneratePdf" type="info" title="Gerar PDF"
          :disabled="selectedPdf.length == 0" classIcon="fa-solid fa-file-pdf" size="medium"
          :clicked="getDocumentPdf" />

        <Button v-if="selectType == 'link'" _key="btnClickedNext" type="info" title="Gerar Link"
          :disabled="selectedPdf.length == 0" classIcon="fa-solid fa-link" size="medium" :clicked="execute"
          :params="'link'" />

        <Button v-if="selectType == 'billet'" _key="btnClickedNext" type="info" title="Ver Boletos"
          classIcon="fa-solid fa-arrow-right" size="medium" :clicked="execute" :params="'billet'" />

      </div>

      <div class="side-by-side">
        <Button v-if="selectType == 'mail'" _key="btnClickedNext" type="info" title=" Escrever"
          :disabled="selectedPdf.length == 0" classIcon="fa-solid fa-pen-nib" size="medium" :clicked="execute"
          :params="'mail'" />

        <Button v-if="selectType == 'signature'" _key="btnClickedNext" type="info" title="Signatário"
          :disabled="selectedPdf.length == 0" classIcon="fa-solid fa-user-plus" size="medium" :clicked="execute"
          :params="'signature'" />
      </div>
    </div>
  </div>
</template>
<script>

import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import ButtonGroup from "@nixweb/nixloc-ui/src/component/forms/ButtonGroup";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ButtonFilter from "@nixweb/nixloc-ui/src/component/forms/ButtonFilter";

import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
  name: "SelectOptionDocument",
  props: [
    "module",
    "rentId",
    "periodRentId",
    "movimentId",
    "listBillet",
    "invoiceId",
    "payableReceivableId",
    "orderServiceId",
    "value",
    "clickedNext",
  ],
  components: {
    CheckboxGroup,
    RadioGroup,
    Button,
    ButtonGroup,
    Alert,
    Loading,
    ButtonFilter,
  },
  data() {
    return {
      urlGetApi: "/api/v1/adm/document/get-all",
      urlGetDocuments: "/api/v1/generate-document/get-documents",
      data: [],
      selectedPrint: "",
      selectedPdf: [],
      link: "",
      loading: true,
      selectType: "print",
      options: [
        {
          title: 'Imprimir',
          classIcon: 'fa-solid fa-print',
          value: 'print',
        },
        {
          title: 'PDF',
          classIcon: 'fa-solid fa-file-pdf',
          value: 'PDF',
        },
        {
          title: 'Boleto',
          classIcon: 'fa-solid fa-barcode',
          value: 'billet',
        },
        {
          title: 'E-mail',
          classIcon: 'fa-solid fa-envelope',
          value: 'mail',
        },
        {
          title: 'Assina..Digital',
          classIcon: 'fa-solid fa-file-signature',
          value: 'signature',
        },
        {
          title: 'Link',
          classIcon: 'fa-solid fa-link',
          value: 'link',
        },
      ]
    };
  },
  created() {
    this.getAll();
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("user", ["hasModule", "hasRule"]),
    ...mapGetters("generic", ["listIdToString"]),
    optionsConditional() {
      let options = [];
      var isModuleBillet = this.hasModule("Billet");
      var isModuleSignature = this.hasRule("Signature");

      this.options.forEach(item => {

        if (item.value != 'billet' && this.module != "TaxDocument" && item.value != "signature")
          options.push(item);

        // tem q aparecer boleto somente se for módulo de Nfse e Faturamento, porem dentro da locação
        if (item.value == 'billet'
          && isModuleBillet
          && this.listBillet.length > 0) options.push(item);

        if (item.value == 'signature' && isModuleSignature && this.module != "Finance") options.push(item);
      });
      return options;
    }
  },
  methods: {
    ...mapActions("generic", ["getApi", "getPdfApi", "postApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading"]),
    getAll() {
      this.loading = true;
      let params = { url: this.urlGetApi, obj: { noPaginated: true } };
      this.getApi(params).then((response) => {
        response.content.data.forEach((item) => {
          let option = { text: item.identification, value: item.id };
          if (item.module.includes(this.module)) {
            this.data.push(option);
          }
        });
        this.loading = false;
      });
    },
    getDocumentPdf() {
      let params = {
        url: this.urlGetDocuments,
        obj: {
          documentTitle: "Documento",
          documentIds: this.listIdToString(this.selectedPdf),
          rentId: this.rentId == null ? "null" : this.rentId,
          periodRentId: this.periodRentId == null ? "null" : this.periodRentId,
          movimentId: this.movimentId == null ? "null" : this.movimentId,
          invoiceId: this.invoiceId == null ? "null" : this.invoiceId,
          payableReceivableId: this.payableReceivableId == null ? "null" : this.payableReceivableId,
          orderServiceId: this.orderServiceId == null ? "null" : this.orderServiceId,
          userName: this.userLogged.user.userName,
          password: this.userLogged.user.password,
          companyId: this.userLogged.companyId
        },
      };
      this.getPdfApi(params).then(() => {
        this.removeLoading(["btnGeneratePdf"]);
      });
    },
    execute(param) {
      if (param == 'view') {
        this.$emit("input", this.selectedPrint);
        this.addEvent({ name: "btnClickedNext", data: { type: param } });
      }

      if (param == 'mail' || param == 'link') {
        this.$emit("input", this.selectedPdf);
      }

      this.addEvent({ name: "btnClickedNext", data: { type: param } });
      this.removeLoading(["btnClickedNext"]);
    },
  },
  watch: {
    selectedPrint: {
      handler() {
        this.$emit("input", this.selectedPrint);
      },
      deep: true,
    },
    selectedPdf: {
      handler() {
        this.$emit("input", this.selectedPdf);
      },
      deep: true,
    },
  },
};
</script>
